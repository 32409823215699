import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">



<path d="M1740 2321 c-53 -17 -129 -62 -220 -131 -156 -120 -271 -213 -267
-217 2 -2 63 28 135 67 317 168 351 183 426 184 67 1 69 1 102 -38 31 -35 34
-45 34 -100 0 -34 -9 -95 -20 -136 l-19 -74 37 -45 37 -45 27 115 c45 188 35
292 -33 360 -57 57 -162 83 -239 60z m172 -49 c14 -12 28 -19 33 -15 4 5 5 2
1 -4 -4 -7 -3 -13 3 -13 30 0 83 -143 64 -174 -4 -6 -6 -23 -5 -37 2 -14 -4
-45 -12 -70 -9 -24 -16 -52 -16 -61 0 -9 -5 -20 -11 -24 -23 -14 -25 19 -8 94
32 134 20 208 -43 255 -62 46 -185 35 -288 -26 -69 -42 -110 -59 -110 -48 0 5
16 19 35 31 20 12 42 28 49 36 8 7 40 29 72 49 56 33 63 35 136 33 63 -3 81
-7 100 -26z"/>
<path d="M1146 2306 c-60 -22 -88 -48 -118 -106 -21 -39 -28 -66 -28 -110 0
-105 34 -168 225 -410 133 -168 146 -181 103 -100 -20 36 -77 148 -129 250
-85 169 -93 191 -97 253 l-4 67 41 41 41 42 74 -6 c40 -3 101 -13 134 -22 l61
-17 40 33 c22 19 41 36 41 39 0 3 -44 16 -97 29 -128 30 -236 36 -287 17z
m218 -31 c43 -8 80 -16 83 -18 2 -2 0 -12 -3 -21 -5 -12 -13 -16 -28 -12 -52
15 -160 36 -185 36 -15 0 -46 -10 -70 -22 -58 -30 -88 -85 -86 -153 2 -63 13
-95 74 -220 27 -55 41 -89 30 -75 -10 14 -24 31 -30 39 -39 46 -81 105 -98
140 -23 44 -38 140 -26 168 5 10 9 24 11 31 10 50 122 133 160 118 8 -3 13 -2
10 3 -7 10 44 6 158 -14z"/>
<path d="M1720 1998 c23 -40 81 -153 131 -251 82 -166 89 -183 89 -239 0 -57
-2 -63 -39 -99 -35 -36 -43 -39 -92 -39 -30 0 -92 9 -138 20 l-83 20 -38 -32
c-21 -18 -39 -36 -39 -39 -2 -10 191 -51 264 -56 87 -6 145 9 192 50 50 44 73
98 73 174 0 72 -18 125 -70 203 -50 74 -270 354 -281 358 -5 2 9 -29 31 -70z
m182 -237 c39 -46 99 -148 109 -184 22 -83 -15 -193 -79 -235 -31 -21 -127
-45 -142 -37 -3 1 -27 5 -55 9 -75 9 -148 30 -153 44 -5 16 37 15 123 -3 87
-19 123 -19 166 -1 81 34 111 112 84 217 -9 35 -30 87 -46 115 -16 28 -29 59
-29 68 0 9 -4 16 -9 16 -5 0 -11 12 -14 27 l-5 28 19 -25 c10 -14 24 -31 31
-39z"/>
<path d="M1456 1861 c-42 -47 -8 -124 54 -124 62 0 97 77 55 123 -24 26 -85
27 -109 1z m80 -29 c6 -12 14 -19 19 -14 4 4 3 -3 -4 -15 -12 -23 -29 -37 -46
-38 -15 -2 -45 26 -45 42 0 19 33 52 50 50 8 -1 20 -12 26 -25z"/>
<path d="M1031 1714 c-58 -244 -31 -362 93 -420 94 -44 184 -30 298 47 91 61
370 281 364 286 -3 3 -31 -11 -63 -30 -32 -19 -141 -77 -243 -128 -179 -91
-187 -94 -253 -94 -66 0 -70 1 -103 37 -34 38 -38 53 -35 117 2 34 25 153 35
181 3 9 -11 33 -32 59 l-38 43 -23 -98z m61 5 c2 -6 -4 -53 -14 -103 -18 -89
-17 -186 2 -186 5 0 11 -9 15 -19 3 -11 26 -29 51 -41 71 -35 142 -25 257 34
132 69 152 75 102 31 -56 -50 -149 -111 -188 -125 -60 -19 -81 -21 -132 -9
-124 29 -180 120 -156 255 6 32 13 73 15 89 12 67 18 85 31 85 7 0 15 -5 17
-11z"/>
<path d="M1222 1165 c0 -19 4 -35 9 -35 5 0 9 7 9 15 0 8 7 15 15 15 8 0 15
-7 15 -15 0 -17 31 -20 47 -4 8 8 17 7 32 -3 18 -11 25 -10 48 4 37 22 37 52
1 56 -24 3 -27 -1 -30 -30 l-4 -33 -13 33 c-16 40 -26 41 -40 1 -12 -35 -34
-30 -25 7 4 14 2 24 -5 24 -6 0 -11 -7 -11 -15 0 -8 -7 -15 -15 -15 -8 0 -15
7 -15 15 0 8 -4 15 -9 15 -5 0 -9 -16 -9 -35z"/>
<path d="M1440 1166 c0 -33 1 -34 25 -23 35 17 35 57 0 57 -22 0 -25 -4 -25
-34z"/>
<path d="M1500 1198 c0 -3 6 -20 13 -37 l13 -31 16 35 c8 19 11 35 6 35 -5 0
-11 -7 -15 -16 -6 -15 -8 -15 -20 2 -7 10 -13 15 -13 12z"/>
<path d="M1590 1185 c0 -9 7 -18 15 -21 8 -4 15 -11 15 -16 0 -6 -7 -8 -15 -5
-8 4 -17 2 -20 -3 -4 -6 5 -10 19 -10 32 0 40 14 19 35 -14 14 -14 18 -2 26
11 7 9 9 -8 9 -14 0 -23 -6 -23 -15z"/>
<path d="M1644 1178 c3 -13 6 -29 6 -35 0 -19 47 -16 54 3 9 24 7 54 -4 54 -5
0 -10 -13 -10 -30 0 -20 -5 -30 -15 -30 -10 0 -15 10 -15 30 0 17 -5 30 -11
30 -7 0 -9 -9 -5 -22z"/>
<path d="M1720 1165 c0 -34 2 -36 28 -33 23 3 27 8 27 33 -1 25 -5 31 -28 33
-25 3 -27 1 -27 -33z"/>
<path d="M921 1053 c8 -9 13 -24 11 -34 -2 -9 2 -20 8 -24 6 -4 10 5 10 22 0
16 5 34 12 41 9 9 5 12 -21 12 -30 0 -32 -2 -20 -17z"/>
<path d="M980 1030 c0 -33 3 -40 19 -40 11 0 23 5 26 10 3 6 -3 10 -14 10 -25
0 -28 20 -3 21 14 0 14 2 -3 9 -19 8 -19 8 5 18 l25 11 -27 0 c-26 1 -28 -2
-28 -39z"/>
<path d="M1040 1035 c0 -35 22 -57 38 -40 4 4 1 10 -5 12 -7 3 -13 18 -13 34
0 16 -4 29 -10 29 -5 0 -10 -16 -10 -35z"/>
<path d="M1092 1033 c2 -31 7 -39 26 -41 12 -2 22 1 22 7 0 6 -7 11 -15 11
-21 0 -19 18 3 23 16 4 16 5 0 6 -10 0 -18 5 -18 10 0 5 8 12 18 14 10 3 6 5
-11 6 -27 1 -28 -1 -25 -36z"/>
<path d="M1162 1058 c-28 -28 0 -78 35 -63 16 7 15 9 -7 14 -31 9 -33 35 -2
39 12 2 22 8 22 13 0 13 -34 11 -48 -3z"/>
<path d="M1224 1044 c-9 -34 18 -64 44 -48 22 14 27 41 12 59 -20 24 -49 18
-56 -11z m51 -14 c0 -22 -39 -19 -43 3 -3 15 1 18 20 15 12 -2 23 -10 23 -18z"/>
<path d="M1301 1033 c1 -25 3 -30 6 -15 4 22 5 23 19 5 11 -15 15 -16 23 -4 7
11 10 10 14 -5 2 -10 5 -1 6 21 l1 40 -19 -24 -19 -24 -6 21 c-11 35 -26 25
-25 -15z"/>
<path d="M1391 1033 c0 -30 2 -34 9 -18 7 18 8 19 15 3 8 -20 25 -14 25 8 0 8
-5 12 -11 9 -6 -4 -13 2 -16 14 -9 34 -23 24 -22 -16z"/>
<path d="M1482 1039 c-2 -40 20 -58 44 -38 16 13 19 69 4 69 -5 0 -10 -13 -10
-30 0 -42 -27 -40 -33 3 l-4 32 -1 -36z"/>
<path d="M1550 1035 c0 -19 4 -35 9 -35 5 0 7 10 4 23 l-5 22 20 -22 c28 -31
32 -29 31 15 -1 32 -2 34 -8 12 l-7 -25 -15 23 c-20 31 -29 27 -29 -13z"/>
<path d="M1660 1055 c-11 -14 -6 -54 8 -56 29 -6 62 -3 62 4 0 5 13 7 29 4
l28 -6 -16 35 c-19 42 -26 43 -40 4 -7 -23 -16 -30 -35 -30 -19 0 -26 5 -26
20 0 13 7 20 20 20 11 0 20 5 20 10 0 15 -36 12 -50 -5z"/>
<path d="M1790 1060 c0 -5 5 -10 10 -10 6 0 11 -15 11 -32 l2 -33 6 34 c9 49
29 51 34 4 l4 -38 1 43 c2 42 2 42 -33 42 -19 0 -35 -4 -35 -10z"/>
<path d="M1881 1051 c-19 -35 9 -68 45 -54 10 4 14 16 12 37 -4 38 -40 49 -57
17z m47 -18 c-5 -26 -38 -29 -38 -4 0 15 6 21 21 21 14 0 19 -5 17 -17z"/>
<path d="M2000 1049 c0 -11 -4 -17 -10 -14 -14 9 -13 -8 2 -23 20 -20 28 -14
28 23 0 19 -4 35 -10 35 -5 0 -10 -9 -10 -21z"/>
<path d="M2034 1058 c-4 -6 0 -16 9 -23 21 -15 22 -25 1 -25 -8 0 -12 -4 -9
-10 10 -16 35 -12 42 6 4 11 -1 22 -12 30 -17 13 -17 14 -2 14 9 0 17 5 17 10
0 14 -37 13 -46 -2z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
